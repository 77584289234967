import { io } from 'socket.io-client'
import EventEmitter from 'events'

let socket
const socketEventEmitter = new EventEmitter()

const connectSocket = (serverUrl, userId) => {
  if (!socket) {
    if (serverUrl === '/api') {
      socket = io('', {
        path: '/socket.io/',
        query: { userId },
      })
    } else {
      socket = io(serverUrl, {
        query: { userId },
      })
    }

    socket.on('connect', () => {
      console.log('Connected to WebSocket server')
    })

    socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket server')
    })

    // Register taskCompleted event
    socket.on('taskCompleted', (data) => {
      socketEventEmitter.emit('taskCompleted', data)
    })

    // Register taskActivated event
    socket.on('taskActivated', (data) => {
      socketEventEmitter.emit('taskActivated', data)
    })

    // Register taskFailed event
    socket.on('taskFailed', (data) => {
      socketEventEmitter.emit('taskFailed', data)
    })

    // Register taskLoadUpdate event
    socket.on('taskLoadUpdate', (data) => {
      socketEventEmitter.emit('taskLoadUpdate', data)
    })
  }

  return socketEventEmitter
}

export { connectSocket, socketEventEmitter }
