import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Dialog, DialogActions, DialogContent, TextField, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import SentimentVerySatisfiedOutlinedIcon from '@mui/icons-material/SentimentVerySatisfiedOutlined'
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined'
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined'
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined'
import { useAuth } from '../../context/AuthContext'
import { useNotification } from "../../context/NotificationContext"
import UserService from '../../services/UserService'

const ReviewModal = ({ reviewModalOpen, setReviewModalOpen }) => {
  const { logout } = useAuth()
  const navigate = useNavigate()
  const showNotification = useNotification()

  const [improvementText, setImprovementText] = useState('')
  const [showImprovementField, setShowImprovementField] = useState(false)

  const handleClose = () => {
    setReviewModalOpen(false)
    setImprovementText('')
    setShowImprovementField(false)
  }

  const handleFeedback = (type) => {
    if (type === 'Needs Improvement') {
      setShowImprovementField(true)
    } else {
      handleSubmit(type)
    }
  }

  const handleSubmit = async (type) => {
    try {
      if (type === 'Needs Improvement') {
        await UserService.updateFeedback('Improvement Feedback: ' + improvementText)
      } else {
        await UserService.updateFeedback('Feedback: ' + type)
      }
      showNotification("Your feedback has been saved!", "success", "", null)
    } catch (error) {
      console.log(error)
      showNotification("Failed to saving feedback!", "error", "", null)
    } finally {
      setReviewModalOpen(false)
      logout()
      navigate('/login')
    }
  }

  return (
    <Dialog className="headerReviewModalContainer" open={reviewModalOpen} onClose={handleClose}>
      <DialogContent className="dialogContentContainer">
        <IconButton
          onClick={handleClose}
          sx={{ position: 'absolute', top: 3, right: 1, zIndex: 100, color: '#2D4356' }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" className="dialogContentTitle">How was your experience with Treegress?</Typography>
        { showImprovementField ?
          <TextField
            multiline
            rows={4}
            placeholder="Please share what can be improved"
            value={improvementText}
            onChange={(e) => setImprovementText(e.target.value)}
            variant="outlined"
            fullWidth
            sx={{ mt: 2 }}
          /> :
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 3, mb: 2, gap: 2 }}>
            <Button
              variant="outlined"
              color="success"
              sx={{ width: '230px' }}
              onClick={() => handleFeedback('Great')}
            >
              <SentimentVerySatisfiedOutlinedIcon sx={{ mr: 0.5 }} />
              Great
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              sx={{ width: '230px' }}
              onClick={() => handleFeedback('It’s Okay')}
            >
              <SentimentSatisfiedOutlinedIcon sx={{ mr: 0.5 }}/>
              It’s Okay
            </Button>
            <Button
              variant="outlined"
              color="error"
              sx={{ width: '230px' }}
              onClick={() => handleFeedback('Needs Improvement')}
            >
              <SentimentDissatisfiedOutlinedIcon sx={{ mr: 0.5 }}/>
              Needs Improvement
            </Button>
            <Button
              variant="outlined"
              color="primary"
              sx={{ width: '230px' }}
              onClick={logout}
            >
              <WatchLaterOutlinedIcon sx={{ mr: 0.5 }}/>
              Ask me later
            </Button>
          </Box>
        }
      </DialogContent>

      <DialogActions
        sx={{
          padding: showImprovementField ? "0 16px 16px 16px" : 0
        }}
      >
        {showImprovementField ?
          <Button onClick={() => handleSubmit('Needs Improvement')} color="primary" variant="contained">
            Submit
          </Button> : null
        }
      </DialogActions>
    </Dialog>
  )
}

export default ReviewModal