import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Pagination,
  CircularProgress
} from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { socketEventEmitter } from '../socket'
import { calculateRunTime } from '../utils/calculateRunTime'
import { useProject } from '../context/ProjectContext'
import TaskService from '../services/TaskService'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'

const statusStyles = {
  'pending': { backgroundColor: '#6D6E6F', color: '#fff' },
  'in_progress': { backgroundColor: '#F8DF73', color: '#2D4356' },
  'completed': { backgroundColor: '#198754', color: '#fff' },
  'failed': { backgroundColor: '#D32F2F', color: '#fff' }
}

const TaskManagement = () => {
  const navigate = useNavigate()
  const { selectedProject } = useProject()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const page = parseInt(query.get('page')) || 1
  const [tasksList, setTasksList] = useState([])
  const [tasksTotal, setTasksTotal] = useState(0)
  const [sortBy, setSortBy] = useState(query.get('sort') || 'started_at')
  const [sortDirection, setSortDirection] = useState(query.get('sort_direction') || 'desc')
  const tasksPerPage = 10
  const startItem = (page - 1) * tasksPerPage + 1
  const endItem = Math.min(page * tasksPerPage, tasksTotal)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await TaskService.getTasksByProjectId(page, selectedProject?.id, sortBy, sortDirection)
        if (!res.tasks.length && page > 1) {
          if (sortBy === 'started_at' && sortDirection === 'desc') {
            navigate(`?page=${page-1}`)
          } else {
            navigate(`?page=${page-1}&sort=${sortBy}&sort_direction=${sortDirection}`)
          }
        }

        setTasksTotal(res.total)
        setTasksList(res.tasks)
      } catch (error) {
        console.error('Error fetching tasks:', error)
      }
    }

    fetchData()

    const handleTaskCompleted = async () => {
      fetchData()
    }

    const handleTaskActivated = async () => {
      fetchData()
    }

    const handleTaskFailed = async () => {
      fetchData()
    }

    socketEventEmitter.on('taskCompleted', handleTaskCompleted)
    socketEventEmitter.on('taskActivated', handleTaskActivated)
    socketEventEmitter.on('taskFailed', handleTaskFailed)

    return () => {
      socketEventEmitter.off('taskCompleted', handleTaskCompleted)
      socketEventEmitter.off('taskActivated', handleTaskActivated)
      socketEventEmitter.off('taskFailed', handleTaskFailed)
    }
  }, [page, navigate, selectedProject?.id, sortBy, sortDirection])

  const handlePageChange = (event, value) => {
    if (value !== 1) {
      if (sortBy === 'started_at' && sortDirection === 'desc') {
        navigate(`?page=${value}`)
      } else {
        navigate(`?page=${value}&sort=${sortBy}&sort_direction=${sortDirection}`)
      }
    } else {
      if (sortBy === 'started_at' && sortDirection === 'desc') {
        navigate(`/tasks`)
      } else {
        navigate(`/tasks?sort=${sortBy}&sort_direction=${sortDirection}`)
      }
    }
  }

  const handleRowClick = (task) => {
    if (task.task_type !== 'UI Scan') {
      navigate('/pages', { state: { view: 'hierarchical' } })
    } else {
      navigate(`/pages/${task.page_id}`)
    }
  }

  const handleSortChange = (column) => {
    const isAsc = sortBy === column && sortDirection === 'asc'
    const newSortDirection = isAsc ? 'desc' : 'asc'
    setSortDirection(newSortDirection)
    setSortBy(column)
    navigate(`?page=${page}&sort=${column}&sort_direction=${newSortDirection}`)
  }

  return (
    <Box>
      <Helmet>
        <title>{`Treegress - ${selectedProject.name} Tasks`}</title>
      </Helmet>
      <Header title="Tasks" />
      <Box sx={{ display: 'flex', pt: '56px' }}>
        <Sidebar />
        <Box className='tasks'>
          <Typography variant="h6" gutterBottom>
            Task Management
          </Typography>
          <TableContainer className='tasksTableContainer' component={Paper}>
            <Table className='tasksTable' stickyHeader>
              <TableHead>
                <TableRow
                  sx={{
                    '& th': {
                      position: 'sticky',
                      top: 0,
                      zIndex: 2,
                      borderBottom: '2px solid #D7E2EC',
                    },
                  }}
                >
                  <TableCell align='center' sx={{ width: '180px', minWidth: '180px', cursor: 'pointer' }} onClick={() => handleSortChange('type')}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      {sortBy === 'type' && (sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />)}
                      Task Type
                    </Box>
                  </TableCell>
                  <TableCell align='center' sx={{ minWidth: '220px', cursor: 'pointer' }} onClick={() => handleSortChange('name')}>
                    <Box className="tableCellContentWithBorder" sx={{ display: 'flex', justifyContent: 'center' }}>
                      {sortBy === 'name' && (sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />)}
                      Page Name
                    </Box>
                  </TableCell>
                  <TableCell align='center' sx={{ width: '248px', minWidth: '248px', cursor: 'pointer' }} onClick={() => handleSortChange('started_at')}>
                    <Box className="tableCellContentWithBorder" sx={{ display: 'flex', justifyContent: 'center' }}>
                      {sortBy === 'started_at' && (sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />)}
                      Started
                    </Box>
                  </TableCell>
                  <TableCell align='center' sx={{ width: '140px', minWidth: '140px', cursor: 'pointer' }} onClick={() => handleSortChange('run_time')}>
                    <Box className="tableCellContentWithBorder" sx={{ display: 'flex', justifyContent: 'center' }}>
                      {sortBy === 'run_time' && (sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />)}
                      Run Time
                    </Box>
                  </TableCell>
                  <TableCell align='center' sx={{ width: '140px', minWidth: '140px', cursor: 'pointer' }} onClick={() => handleSortChange('status')}>
                    <Box className="tableCellContentWithBorder" sx={{ display: 'flex', justifyContent: 'center' }}>
                      {sortBy === 'status' && (sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />)}
                      Status
                    </Box>
                  </TableCell>
                  <TableCell align='center' sx={{ minWidth: '120px', cursor: 'pointer' }} onClick={() => handleSortChange('details')}>
                    <Box className="tableCellContentWithBorder" sx={{ display: 'flex', justifyContent: 'center' }}>
                      {sortBy === 'details' && (sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />)}
                      Details
                    </Box>
                  </TableCell>
                  <TableCell align='center' sx={{ minWidth: '200px', cursor: 'pointer' }} onClick={() => handleSortChange('error')}>
                    <Box className="tableCellContentWithBorder" sx={{ display: 'flex', justifyContent: 'center' }}>
                      {sortBy === 'error' && (sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />)}
                      Error
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tasksList.length ?
                  tasksList.map((task) => (
                    <TableRow
                      key={task.id}
                      onClick={() => handleRowClick(task)}
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        }
                      }}
                    >
                      <TableCell>{task.task_type}</TableCell>
                      <TableCell>{task.page_name ? task.page_name : 'N/A'}</TableCell>
                      <TableCell align='center'><Box ml={2}>{task.status !== "pending" ? new Date(task.started_at).toLocaleString() : 'N/A'}</Box></TableCell>
                      <TableCell align='center'><Box ml={2}>{task.status !== "pending" ? calculateRunTime(task.started_at, task.updated_at) : null}</Box></TableCell>
                      <TableCell align='center'>
                        <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', ml: 2 }}>
                          <Box
                            variant="contained"
                            size="small"
                            sx={{
                              ...statusStyles[task.status],
                              display: 'flex',
                              justifyContent: 'center',
                              width: '150px',
                              minWidth: '120px',
                              fontSize: '14px',
                              borderRadius: '16px',
                              p: '4px 16px',
                              textTransform: 'capitalize'
                            }}
                          >
                            { task.status === "pending" ?
                              <CircularProgress color="white" size="18px" sx={{ mr: 1 }} /> :
                              task.status === "in_progress" ?
                                <CircularProgress color="primary" size="18px" sx={{ mr: 1 }} /> :
                                null }
                            { task.status === "in_progress" ? "in progress" : task.status }
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align='center'>
                        { task?.Page?.screen_size && task.task_type === "UI Scan" ?
                          <Typography sx={{ ml: 2, textTransform: 'capitalize' }}>{task.Page.screen_size}</Typography> : ''}
                      </TableCell>
                      <TableCell sx={{ wordBreak: 'break-word' }}>{task.error ? task?.error : ''}</TableCell>
                    </TableRow>
                  )) :
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Typography variant="body1" align="center">
                          No data available
                      </Typography>
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
          {tasksList.length ?
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center',  mt: 2 }}>
              <Typography variant="body2" mr={1}>
                {`${startItem}-${endItem} of ${tasksTotal}`}
              </Typography>
              <Pagination
                count={Math.ceil(tasksTotal / tasksPerPage)}
                page={page}
                onChange={handlePageChange}
                shape="rounded"
                color='primary'
              />
            </Box> : null
          }
        </Box>
      </Box>
    </Box>
  )
}

export default TaskManagement