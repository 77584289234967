import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Tab } from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Helmet } from 'react-helmet-async'
import { useProject } from "../context/ProjectContext"
import ProjectService from '../services/ProjectService'
import PageService from '../services/PageService'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import OverviewTab from '../components/discoveryPage/tabs/overview/OverviewTab'
import TestRecommendationsTab from '../components/discoveryPage/tabs/testRecommendations/TestRecommendationsTab'

const DiscoveryPage = () => {
  const { id } = useParams()
  const { selectedProject } = useProject()
  const [activeTabValue, setActiveTabValue] = useState('1')
  const [page, setPage] = useState(null)
  const [allResolutionPages, setAllResolutionPages] = useState([])
  const [discoveryPageToggler, setDiscoveryPageToggler] = useState(false)

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const pageData = await PageService.getPageById(id)
        setPage(pageData)

        if (pageData) {
          const allPagesData = await PageService.getAllPagesByProjectId(pageData.page.project_id)
          const projectData = await ProjectService.getProjectById(pageData.page.project_id)
          const filteredPages = allPagesData.filter(page => page.url === pageData.page.url && projectData.screen_sizes.includes(page.screen_size))
          setAllResolutionPages(filteredPages)
        }
      } catch (error) {
        console.error('Error fetching Pages:', error)
      }
    }

    fetchPageData()
  }, [id, discoveryPageToggler])

  const handleChangeActiveTab = (event, newValue) => {
    setActiveTabValue(newValue)
  }

  return (
    <Box className="discoveryPage">
      <Helmet>
        <title>{`Treegress - ${selectedProject.name} ${page?.page.label}`}</title>
      </Helmet>
      <Header title={page?.page.label}/>
      <Box sx={{ display: 'flex', pt: '56px' }}>
        <Sidebar />
        <Box className="discoveryPageContentContainer">
          <TabContext value={activeTabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'rgba(45,67,86,0.5)' }}>
              <TabList onChange={handleChangeActiveTab}>
                <Tab label="Overview" value="1" sx={{ fontSize: '1.25rem', textTransform: 'capitalize' }} />
                <Tab label="Test recommendations" value="2" sx={{ fontSize: '1.25rem', textTransform: 'capitalize' }} />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ p: "16px 0" }}>
              <OverviewTab page={page} allResolutionPages={allResolutionPages} discoveryPageToggler={discoveryPageToggler} setDiscoveryPageToggler={setDiscoveryPageToggler}/>
            </TabPanel>
            <TabPanel value="2" sx={{ p: "16px 0" }}>
              <TestRecommendationsTab page={page} />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </Box>
  )
}

export default DiscoveryPage