import { createContext, useState, useEffect, useContext } from 'react'
import ProjectService from '../services/ProjectService'
import { useAuth } from './AuthContext'

const AllProjectsContext = createContext()

export const AllProjectsProvider = ({ children }) => {
  const { userId } = useAuth()
  const [allProjects, setAllProjects] = useState(() => {
    if (!userId) return []
    const savedProjects = localStorage.getItem(`allProjects_${userId}`)
    return savedProjects ? JSON.parse(savedProjects) : []
  })

  const fetchProjects = async () => {
    try {
      const res = await ProjectService.getAllProjects(1, 1000)
      setAllProjects(res.projects)
      localStorage.setItem(`allProjects_${userId}`, JSON.stringify(res.projects))
    } catch (error) {
      console.error('Error fetching projects:', error)
    }
  }

  useEffect(() => {
    if (userId && allProjects.length === 0) {
      fetchProjects()
    }
  // eslint-disable-next-line
  }, [userId, allProjects.length])

  useEffect(() => {
    if (!userId) {
      setAllProjects([])
    }
  }, [userId])

  return (
    <AllProjectsContext.Provider value={{ allProjects, setAllProjects, refreshProjects: fetchProjects }}>
      {children}
    </AllProjectsContext.Provider>
  )
}

export const useAllProjects = () => {
  return useContext(AllProjectsContext)
}