import { Box, Modal, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const FullScreenshotModal = ({ open, handleClose, fullPageBlock }) => (
  <Modal
    open={open}
    onClose={handleClose}
    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
  >
    <Box
      sx={{
        position: 'relative',
        width: '90%',
        maxWidth: '1200px',
        maxHeight: '90%',
        bgcolor: 'background.paper',
        p: '40px',
        overflow: 'auto',
        '&::-webkit-scrollbar': { width: '6px' },
        '&::-webkit-scrollbar-thumb': { backgroundColor: 'primary.main', borderRadius: '6px' },
      }}
    >
      <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 3, right: 1 }}>
        <CloseIcon />
      </IconButton>
      <img
        src={fullPageBlock?.img_storage_link}
        alt="Full Page Screenshot"
        style={{ width: '100%', maxHeight: '100%', objectFit: 'contain' }}
      />
    </Box>
  </Modal>
)

export default FullScreenshotModal
