import { useState, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom"
import { Box, Typography, Divider } from '@mui/material'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
// import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined'
// import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
// import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined'
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined'
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined'
import { socketEventEmitter } from '../socket'
import { useSidebar } from '../context/SidebarContext'
import { useProject } from '../context/ProjectContext'
import TaskService from '../services/TaskService'
import ProjectService from '../services/ProjectService'

const Sidebar = ({ projectsListLength=1 }) => {
  const location = useLocation()
  const { collapsedSidebar } = useSidebar()
  const { selectedProject, setSelectedProject } = useProject()
  const [tasks, setTasks] = useState([])
  const [processingTasksTotal, setProcessingTasksTotal] = useState(null)

  const menuItems = [
    { path: '/', id: 'projectsMenuItem', label: 'Projects', icon: <HomeOutlinedIcon sx={{ mr: '8px' }} /> },
    { path: '/dashboard', id: 'dashboardMenuItem', label: 'Dashboard', icon: <DashboardOutlinedIcon sx={{ mr: '8px' }} /> },
    { path: '/pages', id: 'pagesMenuItem', label: 'Pages', icon: <FactCheckOutlinedIcon sx={{ mr: '8px' }} /> },
    { path: '/tasks', id: 'tasksMenuItem', label: 'Tasks', icon: <AssignmentOutlinedIcon sx={{ mr: '8px' }} />, total: processingTasksTotal },
    // { path: '/#', label: 'Test Cases', icon: <ScienceOutlinedIcon sx={{ mr: '8px' }} /> },
    // { path: '/#', label: 'Test Suites', icon: <AccountTreeOutlinedIcon sx={{ mr: '8px' }} /> },
    // { path: '/#', label: 'Test Runs', icon: <ManageSearchOutlinedIcon sx={{ mr: '8px' }} /> }
  ]

  const externalMenuItems = [
    { url: 'https://treegress.notion.site/', label: 'Product Roadmap', icon: <RouteOutlinedIcon sx={{ mr: '8px' }} /> },
    { url: 'https://tally.so/r/nPBNvQ', label: 'Bug Report', icon: <BugReportOutlinedIcon sx={{ mr: '8px', pr: '2px' }} /> },
    { url: 'https://tally.so/r/3yq2oX', label: 'Feature Request', icon: <TipsAndUpdatesOutlinedIcon sx={{ mr: '8px' }} /> },
  ]

  useEffect(() => {
    const fetchData = async () => {
      try {
        let res
        if (selectedProject?.id) {
          res = await TaskService.getAllTasksByProjectId(selectedProject?.id)
        } else {
          const firstProject = await ProjectService.getAllProjects(1, 1)
          if (firstProject.projects?.[0]) {
            setSelectedProject(firstProject.projects[0])
            res = await TaskService.getAllTasksByProjectId(firstProject.projects[0].id)
          }
        }
        setTasks(res?.tasks)
        const newProcessingTasksTotal = res?.tasks.filter(task => task.status === 'pending' || task.status === 'in_progress').length
        setProcessingTasksTotal(newProcessingTasksTotal)
      } catch (error) {
        console.error('Error fetching tasks:', error)
      }
    }

    fetchData()

    const handleTaskCompleted = async () => {
      fetchData()
    }

    const handleTaskActivated = async () => {
      fetchData()
    }

    const handleTaskFailed = async () => {
      fetchData()
    }

    socketEventEmitter.on('taskCompleted', handleTaskCompleted)
    socketEventEmitter.on('taskActivated', handleTaskActivated)
    socketEventEmitter.on('taskFailed', handleTaskFailed)

    return () => {
      socketEventEmitter.off('taskCompleted', handleTaskCompleted)
      socketEventEmitter.off('taskActivated', handleTaskActivated)
      socketEventEmitter.off('taskFailed', handleTaskFailed)
    }
    // eslint-disable-next-line
  }, [selectedProject?.id])

  const formatTotal = (total) => {
    return total >= 1000 ? `${Math.floor(total / 1000)}k` : total
  }

  const handleOpenExternalLink = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: collapsedSidebar ? '64px' : '242px',
        minWidth: collapsedSidebar ? '64px' : '242px',
        height: 'calc(100vh - 56px)',
        p: '16px 10px',
        bgcolor: 'primary.main',
        transition: 'width 0.3s ease, min-width 0.3s ease'
      }}
    >
      <Box>
        {menuItems.map((item, index) => {
          const isProjectMenu = index === 0
          const isDashboardMenu = index === 1
          const isPagesMenu = index === 2
          const isTasksMenu = index === 3
          const isClickable = isProjectMenu ||
          (isDashboardMenu && projectsListLength && tasks?.[tasks.length - 1]?.status === "completed") ||
          (isPagesMenu && projectsListLength && tasks?.[tasks.length - 1]?.status === "completed") ||
          (isTasksMenu && projectsListLength)

          return (
            <Link
              to={isClickable ? item.path : '#'}
              key={item.label}
              id={item.id}
              style={{ textDecoration: 'none' }}
            >
              <Typography
                variant="h6"
                sx={{
                  position: 'relative',
                  width: collapsedSidebar ? '44px' : '224px',
                  height: '44px',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '18px',
                  color: '#fff',
                  bgcolor: location.pathname === item.path && isClickable ? 'rgba(255, 255, 255, 0.25) !important' : 'transparent',
                  borderRadius: '4px',
                  mb: 1,
                  px: '10px',
                  py: 0.75,
                  opacity: isClickable ? 1 : 0.5,
                  cursor: isClickable ? 'pointer' : 'default',
                  '&:hover': {
                    color: '#fff',
                    bgcolor: isClickable ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                  }
                }}
              >
                {item.icon}
                { collapsedSidebar ? null : item.label }
                { collapsedSidebar && item.total ?
                  <span className='tasksMenuCollapsedBadge'>{formatTotal(item.total)}</span> :
                  !collapsedSidebar && item.total ?
                    <span className='tasksMenuExpandedBadge'>{formatTotal(item.total)}</span> :
                    null
                }
              </Typography>
              {index === 0 || index === 3 ? <Divider sx={{ borderColor: '#fff', mt: '-4px', mb: '4px' }} /> : null}
            </Link>
          )
        })}
      </Box>
      <Box sx={{ mb: '20px' }}>
        {externalMenuItems.map((item) => {
          return (
            <Typography
              key={item.label}
              variant="h6"
              onClick={() => handleOpenExternalLink(item.url)}
              sx={{
                position: 'relative',
                width: collapsedSidebar ? '44px' : '224px',
                height: '44px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '16px',
                color: 'rgba(255, 255, 255, 0.8)',
                bgcolor: 'transparent',
                borderRadius: '4px',
                mb: 1,
                px: '10px',
                py: 0.75,
                cursor: 'pointer',
                '&:hover': {
                  color: '#fff',
                  bgcolor: 'rgba(255, 255, 255, 0.1)',
                }
              }}
            >
              {item.icon}
              { collapsedSidebar ? null : item.label }
            </Typography>
          )
        })}
      </Box>
    </Box>
  )
}

export default Sidebar