import { Box } from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

const SortingIcon = ({ sortConfig, currentKey }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {sortConfig.key === currentKey && (
        <>
          {sortConfig.direction === 'asc' ? (
            <ArrowUpwardIcon fontSize="small" />
          ) : (
            <ArrowDownwardIcon fontSize="small" />
          )}
        </>
      )}
    </Box>
  )
}

export default SortingIcon