import { Box, Typography } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";

const Charts = ({ summaryData }) => {
  const totalIssues = summaryData?.issueDistribution?.reduce(
    (acc, item) => acc + item.value,
    0
  );

  const pieData = summaryData?.issueDistribution?.map((item) => ({
    id: item.id,
    label: `${item.id} (${((item.value / totalIssues) * 100).toFixed(2)}%)`,
    value: item.value,
  }));

  const barData = summaryData?.issueRateOverTime?.map((item) => ({
    x: new Date(item.x).toLocaleString([], {
      year: "numeric",
      month: "numeric",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }),
    y1: item.y1,
    y2: item.y2,
  }));

  const isPieDataEmpty = !pieData || pieData.length === 0;
  const isBarDataEmpty = !barData || barData.length === 0;

  return (
    <Box className="dashboardDataCharts">
      <Box
        className="pieChartContainer"
        sx={{ position: "relative", height: 400 }}
      >
        <Typography variant="h6" textAlign="center" gutterBottom>
            Issue Distribution by Type{" "}
          <span style={{ whiteSpace: "nowrap" }}>
            (as of Last Scan Date)
          </span>
        </Typography>
        {isPieDataEmpty ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <Typography textAlign="center" color="textSecondary">
              Here you will see UI Issue Distribution by Type once
              you scan at least 1 page and at least 1 issue is
              detected.
            </Typography>
          </Box>
        ) : (
          <PieChart
            series={[
              {
                data: pieData,
                innerRadius: 100,
                outerRadius: 150,
                label: {
                  position: "outside",
                  formatter: (point) => `${point.label}`,
                },
                cy: 400
              },
            ]}
            slotProps={{
              legend: {
                position: {
                  horizontal: "right",
                  vertical: "top",
                },
                direction: "column",
                formatter: (legendItem) =>
                  `${legendItem.label}: ${legendItem.value} (${((legendItem.value / totalIssues) * 100).toFixed(2)}%)`,
              },
            }}
            style={{
              maxWidth: "600px",
              margin: "0 auto",
            }}
          />
        )}
      </Box>
      <Box
        className="barChartContainer"
        sx={{ position: "relative", height: 400 }}
      >
        <Typography variant="h6" textAlign="center" gutterBottom>
          Issue Rate Over Time
        </Typography>
        {isBarDataEmpty ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <Typography textAlign="center" color="textSecondary">
                          Here you will see Issue Rate Over Time once
                          you scan at least 1 page and at least 1 issue is
                          detected.
            </Typography>
          </Box>
        ) : (
          <BarChart
            dataset={barData}
            xAxis={[
              {
                dataKey: "x",
                scaleType: "band",
                label: "Scan Dates",
              },
            ]}
            yAxis={[
              {
                dataKey: "y",
                scaleType: "linear",
                tickFontSize: 16,
                tickMinStep: 1,
              },
            ]}
            series={[
              {
                dataKey: "y1",
                label: "Total Pages Scanned",
              },
              {
                dataKey: "y2",
                label: "Pages with Issues",
              },
            ]}
            style={{
              maxWidth: "600px",
              margin: "0 auto",
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default Charts;
