import axios from 'axios'

// eslint-disable-next-line no-undef
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const axiosInstance = axios.create({
  baseURL: SERVER_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    if ((error.response && error.response.status === 401 && !originalRequest._retry) ||
        (error.response && error.response.status === 400 && error.response.data.error === "Invalid token")) {
      if (originalRequest.url.includes('auth/login') ||
          originalRequest.url.includes('auth/signup') ||
          originalRequest.url.includes('auth/refresh-token')) {
        return Promise.reject(error)
      }

      originalRequest._retry = true
      try {
        const refreshToken = localStorage.getItem('refreshToken')
        if (refreshToken) {
          const response = await axiosInstance.post('/auth/refresh-token', { refresh_token: refreshToken })
          const { access_token } = response.data

          localStorage.setItem('accessToken', access_token)
          localStorage.setItem('refreshToken', response.data.refresh_token)

          axiosInstance.defaults.headers['Authorization'] = `Bearer ${access_token}`
          originalRequest.headers['Authorization'] = `Bearer ${access_token}`

          return axiosInstance(originalRequest)
        }
      } catch (refreshError) {
        // Handle refresh token failure (e.g., log out user, redirect to login)
        console.error('Failed to refresh token', refreshError)
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        // Redirect to login or show a message
        window.location.href = '/login'
      }
    }
    return Promise.reject(error)
  }
);

const ApiService = {
  post: async (endpoint, data) => {
    const response = await axiosInstance.post(endpoint, data)
    return response.data
  },

  get: async (endpoint) => {
    const response = await axiosInstance.get(endpoint)
    return response.data
  },

  put: async (endpoint, data) => {
    const response = await axiosInstance.put(endpoint, data)
    return response.data
  },

  delete: async (endpoint) => {
    const response = await axiosInstance.delete(endpoint)
    return response.data
  },
}

export default ApiService