import ApiService from "./ApiService"

const TaskService = {
  getTasksByProjectId: async function (page=1, projectId, sortBy, sortDirection) {
    const endpoint = `/task/project/${projectId}?page=${page}&sort=${sortBy}&sort_direction=${sortDirection}`
    return await ApiService.get(endpoint)
  },
  getAllTasksByProjectId: async function (projectId) {
    const endpoint = `/task/project/${projectId}?pageSize=1000`
    return await ApiService.get(endpoint)
  },
  getLoadStatus: async function () {
    const endpoint = `/task/load`
    return await ApiService.get(endpoint)
  },
}

export default TaskService