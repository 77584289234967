import ApiService from '../services/ApiService'
import addGoogleAnalytics from './addGoogleAnalytics'

const addExternalScripts = async () => {
  try {
    const res = await ApiService.get('/settings')
    if (res.environment === "production") {
      addGoogleAnalytics(res.googleAnalyticsId)
    }
  } catch (error) {
    console.error('Failed to fetch settings', error)
  }
}

export default addExternalScripts