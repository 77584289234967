import { useState, useEffect  } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, TextField, Button, Link, Typography, Box } from '@mui/material'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { Helmet } from 'react-helmet-async'
import ApiService from '../services/ApiService'
import { useAuth } from '../context/AuthContext'
import { validateEmail, validatePassword } from '../utils/validateForm'
import CustomGoogleLoginButton from '../components/CustomGoogleLoginButton'

const Login = () => {
  const { login } = useAuth()
  const navigate = useNavigate()

  const [settings, setSettings] = useState(null)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const res = await ApiService.get('/settings')
        setSettings(res)
      } catch (error) {
        console.error('Error fetching settings:', error)
      }
    }

    fetchSettings()
  }, [])

  const handleLogin = async (e) => {
    e.preventDefault()

    let valid = true

    if (!validateEmail(email)) {
      setEmailError('Invalid email.')
      valid = false
    } else {
      setEmailError('')
    }

    if (!validatePassword(password)) {
      setPasswordError('Invalid password. Minimum length 6.')
      valid = false
    } else {
      setPasswordError('')
    }

    if (!valid) return

    try {
      const data = await ApiService.post('/auth/login', { email, password })
      const { access_token, refresh_token, user } = data
      login(access_token, refresh_token, user.id)
      navigate('/')
    } catch (error) {
      if ( error.response.data.error === "Illegal arguments: string, object") {
        setError('Your account is connected to Google. Please use the Google button to log in.')
      } else {
        setError(error.response.data.error)
      }
    }
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
    if (emailError) {
      setEmailError('')
    }
    if (error) {
      setError('')
    }
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
    if (passwordError) {
      setPasswordError('')
    }
    if (error) {
      setError('')
    }
  }

  return (
    <Container
      maxWidth="xs"
      sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
    >
      <Helmet>
        <title>Treegress - Login</title>
      </Helmet>
      <Box sx={{ border: '1px solid #C4C4C4', borderRadius: '8px', padding: '32px' }}>
        <Typography variant="h6" align="center" fontWeight="400" gutterBottom>
          Login into my account
        </Typography>
        <form onSubmit={handleLogin}>
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
            required
            error={!!emailError}
            helperText={emailError+' '}
            inputProps={{
              form: {
                autocomplete: 'off',
              }
            }}
          />
          <TextField
            label="Password"
            fullWidth
            margin="normal"
            variant="outlined"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            required
            error={!!passwordError}
            helperText={passwordError+' '}
            sx={{ mt: 0 }}
          />
          {error && <Typography color="error" sx={{ margin: '-26px 0 8px 14px', fontSize: '0.75rem' }}>{error}</Typography>}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 0 }}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ width: '250px', textTransform: 'none' }}
            >
              Sign in
            </Button>
          </Box>
        </form>
        <Box my={2} display="flex" justifyContent="center">
          { settings ?
            <GoogleOAuthProvider clientId={settings.googleClientId}>
              <CustomGoogleLoginButton />
            </GoogleOAuthProvider>
            : null
          }
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '48px'}}>
          {/* <Link href="#" variant="body2">
            Forgot password?
          </Link> */}
          <Link href="/signup" variant="body2">
            Don’t have an account?
          </Link>
        </Box>
      </Box>
    </Container>
  )
}

export default Login