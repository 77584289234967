import { useEffect } from 'react'
import { useAuth } from '../context/AuthContext'
import ApiService from '../services/ApiService'

export default function Hotjar() {
  const { user } = useAuth()

  useEffect(() => {
    const initializeHotjar = async () => {
      try {
        const res = await ApiService.get('/settings')
        if (res.environment === 'production' && res.hotjarId) {

          const script = document.createElement('script')
          script.async = true
          script.innerHTML = `
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:${res.hotjarId},hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `
          document.head.appendChild(script)

          script.onload = () => {
            if (user?.id && window.hj) {
              window.hj('identify', user.id, {})
            }
          }

          script.onerror = () => {
            console.error('Failed to load Hotjar script.')
          }
        }
      } catch (error) {
        console.error('Failed to fetch Hotjar ID:', error)
      }
    }

    initializeHotjar()
  }, [user])

  return null
}