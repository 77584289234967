import { useEffect, useState } from "react"
import { Helmet } from 'react-helmet-async'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Typography, AppBar, Button } from "@mui/material"
import ProjectService from "../services/ProjectService"
import ProjectSummary from "../components/dashboard/ProjectSummary"
import Charts from "../components/dashboard/Charts"
import TopPagesReport from "../components/dashboard/TopPagesReport"
import approvedIcon from '../assets/approved_icon.png'
import testedIcon from '../assets/tested_icon.png'

const SharedReport = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const reportId = location.pathname.split('/')[2]
  const [summaryData, setSummaryData] = useState(null)

  useEffect(() => {
    const loadData = async () => {
      const data = await ProjectService.getProjectReportSummary(reportId)
      setSummaryData(data)
    }
    loadData()
  }, [reportId])

  const hasScans = summaryData?.totalPagesScanned > 0

  return (
    <Box className="dashboard" sx={{ height: "100vh", overflow: "hidden" }}>
      <Helmet>
        <title>Treegress - Quality Assurance Report powered by AI</title>
      </Helmet>
      <Box className="header" sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" sx={{ height: '56px', p: 1.5, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" ml={1} color='#fff'>
            Treegress - Quality Assurance Report powered by AI
          </Typography>
        </AppBar>
      </Box>
      <Box sx={{ display: "flex", pt: "56px", height: "100%" }}>
        { summaryData ?
          <Box className="dashboardDataContainer">
            <Box className="dashboardData">
              <Box className="dashboardDataInfoContainer">
                <ProjectSummary summaryData={summaryData} hasScans={hasScans} />
                <Box sx={{ width: '160px', height: '160px', mt: -4 }}>
                  <img
                    src={summaryData.pagesWithIssues ? testedIcon : approvedIcon}
                    alt={summaryData.pagesWithIssues ? "tested_stamp": "approved_stamp"}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </Box>
              </Box>
              { !hasScans ?
                <Box className="dashboardDataEmpty">
                  <Typography variant="h6" gutterBottom>
                    No scans yet!
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    Head over to Pages to start your first scan. Once you’ve completed a scan, all your data for analysis will appear here.
                  </Typography>
                </Box> :
                <Box className="dashboardDataStatsContainer">
                  <Charts summaryData={summaryData}/>
                  <TopPagesReport pages={summaryData?.topPages}/>
                  <Box className="dashboardDataAction">
                    <Typography className="dashboardDataActionText" variant="body1" textAlign='center'>
                      Want to dive deeper into the detected issues? For a full analysis and detailed insights on your website’s UI, simply add your URL to Treegress. Our AI will scan your site and provide a comprehensive report for free!
                    </Typography>
                    <Button
                      className="dashboardDataActionButton"
                      variant="contained"
                      color="secondary"
                      onClick={() => navigate('/signup')}
                    >
                      Create Your Own Report
                    </Button>
                  </Box>
                </Box>
              }
            </Box>
          </Box> :
          <Box className="dashboardDataContainer">Loading...</Box>
        }
      </Box>
    </Box>
  )
}

export default SharedReport
