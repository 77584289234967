export const sortIssues = (issuesArray) => {
  return issuesArray.sort((a, b) => {
    if (a.b_box_relative_top < b.b_box_relative_top) {
      return -1
    }
    if (a.b_box_relative_top > b.b_box_relative_top) {
      return 1
    }
    if (a.b_box_relative_left < b.b_box_relative_left) {
      return -1
    }
    if (a.b_box_relative_left > b.b_box_relative_left) {
      return 1
    }
    return 0
  })
}