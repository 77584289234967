export const calculateRunTime = (startTime, finishedTime) => {
  const start = new Date(startTime)
  const end = finishedTime ? new Date(finishedTime) : new Date()
  const diff = end - start

  const seconds = Math.floor((diff / 1000) % 60)
  const minutes = Math.floor((diff / 1000 / 60) % 60)
  const hours = Math.floor(diff / 1000 / 60 / 60)

  if (hours > 0) {
    return `${hours}h ${minutes}m ${seconds}s`
  } else if (minutes > 0) {
    return `${minutes}m ${seconds}s`
  } else if ( hours === 0 && minutes === 0 && seconds === 0) {
    return ''
  } else {
    return `${seconds}s`
  }
}