import { Button } from '@mui/material'
import GoogleIcon from '@mui/icons-material/Google'
import { useGoogleLogin } from '@react-oauth/google'
import { useAuth } from '../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import ApiService from '../services/ApiService'

const CustomGoogleLoginButton = () => {
  const { login } = useAuth()
  const navigate = useNavigate()

  const handleGoogleLoginSuccess = async (tokenResponse) => {
    const accessToken = tokenResponse.access_token
    try {
      const data = await ApiService.post('/auth/google-login', { google_access_token: accessToken })
      const { access_token, refresh_token, user } = data
      login(access_token, refresh_token, user.id)
      navigate('/')
    } catch (error) {
      console.error('Error during Google login:', error)
    }
  }

  const handleGoogleLoginError = () => {
    console.log('Login Failed')
  }

  const loginWithGoogle = useGoogleLogin({
    onSuccess: handleGoogleLoginSuccess,
    onError: handleGoogleLoginError,
  })

  return (
    <Button
      variant="outlined"
      color="secondary"
      sx={{ width: '250px', textTransform: 'none' }}
      onClick={() => loginWithGoogle()}
    >
      <GoogleIcon sx={{ height: '16px', marginBottom: '2px' }} />
      Sign in with Google
    </Button>
  )
}

export default CustomGoogleLoginButton
