import { useState, useEffect } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { useSocket } from '../hooks/useSocket'
import { socketEventEmitter } from '../socket'
import { Box, AppBar, Typography, Avatar, Menu, MenuItem, IconButton, Select, FormControl } from '@mui/material'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { useAuth } from '../context/AuthContext'
import { useNotification } from '../context/NotificationContext'
import { useProject } from '../context/ProjectContext'
import { useSidebar } from '../context/SidebarContext'
import { useAllProjects } from '../context/AllProjectsContext'
import TaskService from '../services/TaskService'
import ReviewModal from './modals/ReviewModal'
import TaskLoad from './TaskLoad'

const Header = ({ title, projectsToggler=false }) => {
  useSocket()
  const { user, logout } = useAuth()
  const showNotification = useNotification()
  const navigate = useNavigate()
  const { selectedProject, setSelectedProject } = useProject()
  const { collapsedSidebar, setCollapsedSidebar } = useSidebar()
  const { allProjects } = useAllProjects()
  const { id } = useParams()

  const [anchorEl, setAnchorEl] = useState(null)
  const [reviewModalOpen, setReviewModalOpen] = useState(false)
  const [loadStatus, setLoadStatus] = useState(null)

  const open = Boolean(anchorEl)

  useEffect(() => {
    const isSelectedProjectExist = allProjects.find(project => project?.id === selectedProject?.id)

    if (!isSelectedProjectExist && allProjects.length === 1) {
      setSelectedProject(allProjects[0])
    }

    if (id) {
      const initialProject = allProjects.find(project => project.id === id)
      if (initialProject) {
        setSelectedProject(initialProject)
      }
    }

    const handleTaskCompleted = async (data) => {
      data.type === "UI Scan" ?
        showNotification(`${data.type} has been completed successfully`, 'success', 'Learn more', navigate, `/pages/${data.pageId}`) :
        showNotification(`${data.type} has been completed successfully`, 'success', 'Learn more', navigate, '/pages')
    }

    const handleTaskFailed = async (data) => {
      showNotification(`${data.type} could not be completed`, 'error', 'Learn more', navigate)
    }

    socketEventEmitter.on('taskCompleted', handleTaskCompleted)
    socketEventEmitter.on('taskFailed', handleTaskFailed)

    return () => {
      socketEventEmitter.off('taskCompleted', handleTaskCompleted)
      socketEventEmitter.off('taskFailed', handleTaskFailed)
    }
  // eslint-disable-next-line
  }, [id, allProjects.length, selectedProject?.id, showNotification, projectsToggler])

  useEffect(() => {
    const fetchTaskLoadData = async () => {
      try {
        const res = await TaskService.getLoadStatus()
        setLoadStatus(res?.loadStatus)
      } catch (error) {
        console.error('Error fetching sites:', error)
      }
    }

    fetchTaskLoadData()

    const handleUpdateLoadStatus = async (data) => {
      setLoadStatus(data?.loadStatus)
    }

    socketEventEmitter.on('taskLoadUpdate', handleUpdateLoadStatus)

    return () => {
      socketEventEmitter.off('taskLoadUpdate', handleUpdateLoadStatus)
    }
  }, [])

  const toggleSidebar = () => {
    setCollapsedSidebar((prev) => !prev)
  }

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleReviewModalOpen = () => {
    setReviewModalOpen(true)
  }

  const handleLogout = () => {
    handleMenuClose()
    if (!user.feedback) {
      handleReviewModalOpen()
    } else {
      logout()
      navigate('/login')
    }
  }

  const handleProfile = () => {
    handleMenuClose()
    navigate('/profile')
  }

  const handleProjectChange = (event) => {
    const selectedProjectName = event.target.value
    const selectedProjectItem = allProjects.find(project => project.name === selectedProjectName)
    setSelectedProject(selectedProjectItem)
  }

  return (
    <Box className="header" sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ height: '56px', p: 1.5, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', justifyContent: collapsedSidebar ? 'center' : 'flex-end' }}>
            <IconButton onClick={toggleSidebar}>
              {collapsedSidebar ? <MenuOutlinedIcon /> : <MenuOpenOutlinedIcon />}
            </IconButton>
          </Box>
          <FormControl variant="standard" sx={{ minWidth: 150, mr: 2 }}>
            { allProjects.length && allProjects.find(project => project?.id === selectedProject?.id) ?
              <Select
                value={selectedProject.name}
                onChange={handleProjectChange}
                sx={{ width: '176px', height: '46px', pl: 1 }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      minWidth: '176px !important',
                      maxWidth: '300px',
                      overflow: 'auto'
                    }
                  }
                }}
              >
                { allProjects.map((project) => (
                  <MenuItem key={project.id} value={project.name}>
                    <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {project.name}
                    </Box>
                  </MenuItem>
                ))}
              </Select> :
              <Box sx={{ width: '176px', height: '46px' }}></Box>
            }
          </FormControl>
          <Typography variant="h6" ml={1} color='#fff'>
            {title}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {loadStatus ? <TaskLoad loadStatus={loadStatus} /> : null}
          <IconButton component={Link} to="/tutorial" sx={{ width: '40px', height: '40px', border: '2px solid #fff', mx: 1 }}>
            <QuestionMarkIcon />
          </IconButton>
          <IconButton onClick={handleMenuOpen}>
            <Avatar sx={{ bgcolor: '#4574D2' }} alt="User Avatar"/>
          </IconButton>
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem sx={{ minWidth: '100px', display: 'flex', justifyContent: 'center' }} onClick={handleProfile}>Profile</MenuItem>
          <MenuItem sx={{ minWidth: '100px', display: 'flex', justifyContent: 'center' }} onClick={handleLogout}>Logout</MenuItem>
        </Menu>
        <ReviewModal reviewModalOpen={reviewModalOpen} setReviewModalOpen={setReviewModalOpen}/>
      </AppBar>
    </Box>
  )
}

export default Header