export const formatDate = (dateString) => {
  const date = new Date(dateString)

  if (isNaN(date)) {
    throw new Error('Invalid time value')
  }

  // eslint-disable-next-line
  const formattedDate = new Intl.DateTimeFormat('default', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(date)

  return formattedDate
}