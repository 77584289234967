import { Box } from '@mui/material'

const PreviewImage = ({ page }) => {
  const top = page.block.firstIssue.b_box_relative_top
  // const right = page.block.firstIssue.b_box_relative_right
  // const bottom = page.block.firstIssue.b_box_relative_bottom
  const left = page.block.firstIssue.b_box_relative_left

  const topPX = Math.round(top*1080) - 6
  const leftPX = Math.round(left*1920) - 6

  return (
    <Box
      sx={{
        height: '150px',
        overflow: 'hidden',
        padding: '3px',
        backgroundImage: `url(${page.block.img_storage_link})`,
        backgroundSize: "1920px 1080px",
        backgroundPosition: `left -${leftPX}px top -${topPX}px`,
        backgroundRepeat: "no-repeat"
      }}
    >
      { top && left ? <Box
        sx={{
          width: '100%',
          height: '100%',
          border: '3px solid #D32F2F'
        }}
      /> : null}
    </Box>
  )
}

export default PreviewImage
