import { useState, useEffect } from 'react'
import { Autocomplete, TextField } from '@mui/material'

const CustomSelectWithInput = ({ value, defaultOptions, handleEnvironmentChange }) => {
  const [options, setOptions] = useState(defaultOptions)
  const [selectedValue, setSelectedValue] = useState('')

  useEffect(() => {
    if (value) {
      setSelectedValue(value)
    } else {
      setSelectedValue('')
    }
  }, [value])

  const handleChange = (event, newValue) => {
    if (newValue && !options.includes(newValue)) {
      setOptions((prevOptions) => [...prevOptions, newValue])
    }
    handleEnvironmentChange(newValue)
    setSelectedValue(newValue)
  }

  const handleInputChange = (event, newValue) => {
    handleEnvironmentChange(newValue)
  }

  return (
    <Autocomplete
      freeSolo
      value={selectedValue}
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={options}
      renderInput={(params) => (
        <TextField {...params} placeholder="Select or Add Custom" variant="outlined" size='small' sx={{ minWidth: '300px' }}/>
      )}
    />
  )
}

export default CustomSelectWithInput