import { createContext, useState, useContext } from 'react'

const SidebarContext = createContext()

export const SidebarProvider = ({ children }) => {
  const [collapsedSidebar, setCollapsedSidebar] = useState(false)

  const value = {
    collapsedSidebar,
    setCollapsedSidebar,
  }

  return (
    <SidebarContext.Provider value={value}>
      {children}
    </SidebarContext.Provider>
  )
}

export const useSidebar = () => {
  return useContext(SidebarContext)
}