import { useState } from "react"
import { useNavigate } from 'react-router-dom'
import { Box, Button, Tooltip, Snackbar, Alert, IconButton, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close'
import { useNotification } from "../../context/NotificationContext"

const ShareButton = ({ summaryData }) => {
  const showNotification = useNotification()
  const navigate = useNavigate()
  const [shareSnackbarOpen, setShareSnackbarOpen] = useState(false)

  const handleShareSnackbarClose = () => {
    setShareSnackbarOpen(false)
  }

  const handleShare = async () => {
    try {
      const currentUrl = new URL(window.location.href)
      const domain = currentUrl.origin
      const shareableLink = `${domain}/report/${summaryData.reportId}`

      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(shareableLink)
        showNotification("Report link copied to clipboard!", "success", "Learn more", navigate, `/report/${summaryData.reportId}`)
      } else {
        const textArea = document.createElement("textarea")
        textArea.value = shareableLink
        document.body.appendChild(textArea)
        textArea.select()
        document.execCommand("copy")
        document.body.removeChild(textArea)
        showNotification("Report link copied to clipboard!", "success", "Learn more", navigate, `/report/${summaryData.reportId}`)
      }

      setShareSnackbarOpen(true)
    } catch (error) {
      console.error("Failed to copy report link:", error)
      showNotification("Failed to copy report link!", "error", "", null)
    }
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Box className="dashboardDataInfoShare">
        <Tooltip
          title="Click to generate a shareable link to this QA report. Anyone with the link can view it."
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                backgroundColor: 'primary.main'
              }
            },
          }}
        >
          <Box>
            <Button
              className="dashboardDataInfoShareButton"
              onClick={handleShare}
              disabled={!summaryData.reportId}
              variant="contained"
              color="secondary"
            >
              Share
            </Button>
          </Box>
        </Tooltip>
      </Box>
      <Snackbar
        className="dashboardDataInfoSnackbar"
        open={shareSnackbarOpen}
        autoHideDuration={20000}
        onClose={handleShareSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert className="dashboardDataInfoSnackbarAlert" icon={false} severity="info">
          <Typography variant="body2" className="snackbarAlertText">You’ve just copied a link that is accessible to anyone with it.</Typography>
          <IconButton className="snackbarAlertCloseIcon" onClick={handleShareSnackbarClose} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default ShareButton
