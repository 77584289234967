import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const FullPageAccordion = ({ handleOpen, fullPageBlock }) => (
  <Box sx={{ mb: 3 }}>
    <Accordion sx={{ borderRadius: '8px !important', boxShadow: 'none' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          border: '1px solid #2D4356',
          borderRadius: '8px',
          maxHeight: '50px',
          '&.Mui-expanded': {
            minHeight: '50px',
            borderRadius: '8px 8px 0 0',
          },
        }}
      >
        <Typography>Full page screenshot</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{ p: 0, border: '1px solid #2D4356', borderRadius: '0 0 8px 8px', borderTopWidth: 0 }}
      >
        <Card onClick={handleOpen} sx={{ cursor: 'pointer', borderRadius: '0 0 8px 8px' }}>
          <CardContent sx={{ display: 'flex', justifyContent: 'center', p: '8px !important' }}>
            <img
              src={fullPageBlock?.img_storage_link}
              alt="Full Page Screenshot"
              style={{ maxHeight: '50vh' }}
            />
          </CardContent>
        </Card>
      </AccordionDetails>
    </Accordion>
  </Box>
)

export default FullPageAccordion