import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#2D4356',
      medium: 'rgba(69, 116, 210, 0.3)',
      light: 'rgba(69, 116, 210, 0.05)'
    },
    secondary: {
      main: '#4574D2'
    },
    success: {
      main: '#198754'
    },
    error : {
      main: '#D32F2F',
    },
    white: {
      main: '#FFF'
    }
  },
})

export default theme