const addGoogleAnalytics = (id) => {
  const gaScript = document.createElement('script')
  gaScript.async = true
  gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${id}`
  document.head.insertBefore(gaScript, document.head.firstChild)

  const inlineScript = document.createElement('script')
  inlineScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${id}');
  `
  document.head.insertBefore(inlineScript, document.head.firstChild.nextSibling)
}

export default addGoogleAnalytics