import ApiService from "./ApiService"

const PageService = {
  getPagesByProjectId: async function (projectId) {
    const endpoint = `/pages/project/${projectId}`
    return await ApiService.get(endpoint)
  },
  getAllPagesByProjectId: async function (projectId) {
    const endpoint = `/pages/project/all/${projectId}`
    return await ApiService.get(endpoint)
  },
  getPageById: async function (pageId) {
    const endpoint = `/pages/${pageId}`
    return await ApiService.get(endpoint)
  },
  createPage: async function (projectID, pageName, projectURL, parentID) {
    const endpoint = `/pages`
    return await ApiService.post(endpoint, { label: pageName ? pageName : null, url: projectURL, project_id: projectID, parent_id: parentID })
  },
  updatePages: async function (projectsData) {
    const endpoint = `/pages`
    return await ApiService.put(endpoint, projectsData)
  },
}

export default PageService