import ApiService from "./ApiService"

const UserService = {
  getProfile: async function () {
    const endpoint = `/auth/profile`
    return await ApiService.get(endpoint)
  },
  updateFeedback: async function (feedback) {
    const endpoint = `/auth/feedback`
    return await ApiService.put(endpoint, { feedback })
  }
}

export default UserService