import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { Box, Typography, TextField, Button, FormControl, FormGroup, FormControlLabel, FormHelperText, Checkbox, MenuItem, Select, ListItemText, CircularProgress, Alert, AlertTitle } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined'
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined'
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined'
import OpenInBrowserOutlinedIcon from '@mui/icons-material/OpenInBrowserOutlined'
import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined'
import ProjectService from '../services/ProjectService'
import Header from "../components/Header"
import Sidebar from "../components/Sidebar"
import CustomSelectWithInput from '../components/CustomSelectWithInput'
import { validateProjectName } from '../utils/validateForm'

const ProjectSettings = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const [historyPageId, setHistoryPageId] = useState(null)
  const [project, setProject] = useState(null)
  const [projectName, setProjectName] = useState('')
  const [projectNameError, setProjectNameError] = useState('')
  const [allIssueTypes, setAllIssueTypes] = useState([])
  const [projectIssueTypes, setProjectIssueTypes] = useState(["Server response error (e.g., 401, 403)"])
  const [projectIssueTypesError, setProjectIssueTypesError] = useState(false)
  const [saveProjectLoading, setSaveProjectLoading] = useState(false)
  const [environment, setEnvironment] = useState("Staging")
  const [resolutions, setResolutions] = useState({
    desktop: false,
    tablet: false,
    mobile: false,
  })

  useEffect(() => {
    const getProjectData = async () => {
      const allIssueTypesData = await ProjectService.getIssueTypes()
      setAllIssueTypes(allIssueTypesData)
      const projectData = await ProjectService.getProjectById(id)
      setProject(projectData)
      setProjectName(projectData.name)
      setEnvironment(projectData.environment)
      setResolutions({
        desktop: projectData.screen_sizes.includes('desktop'),
        tablet: projectData.screen_sizes.includes('tablet'),
        mobile: projectData.screen_sizes.includes('mobile'),
      })
      const projectIssueTypesData = allIssueTypesData.filter(type => projectData.issue_class_ids.includes(type.id)).map(type => type.description)
      setProjectIssueTypes(projectIssueTypesData)
    }

    getProjectData()
  }, [id])

  useEffect(() => {
    if (location.state?.historyPageId) {
      setHistoryPageId(location.state.historyPageId)
    }
  }, [location.state])

  const handleProjectNameChange = (e) => {
    setProjectName(e.target.value)
    if (projectNameError) {
      setProjectNameError('')
    }
  }

  const handleEnvironmentChange = (newValue) => {
    setEnvironment(newValue)
  }

  const handleResolutionsChange = (event) => {
    setResolutions({
      ...resolutions,
      [event.target.name]: event.target.checked,
    })
  }

  const handleProjectIssueTypesChange = (event) => {
    const selectedTypes = event.target.value

    if (!selectedTypes.includes("Server response error (e.g., 401, 403)")) {
      setProjectIssueTypes(["Server response error (e.g., 401, 403)", ...selectedTypes])
    } else {
      setProjectIssueTypes(selectedTypes)
    }

    setProjectIssueTypesError(selectedTypes.length === 0)
  }

  const handleSave = async () => {
    let valid = true

    if (!validateProjectName(projectName)) {
      setProjectNameError('Invalid name.')
      valid = false
    }

    if (!valid) return

    const issueClassIds = allIssueTypes.filter(type => projectIssueTypes.includes(type.description)).map(type => type.id)
    const updatedScreenSizes = Object.keys(resolutions).filter(res => resolutions[res])

    try {
      setSaveProjectLoading(true)
      const updatedProjectData = await ProjectService.updateProject(id, projectName, environment, issueClassIds, updatedScreenSizes)
      setProject(updatedProjectData)
      setSaveProjectLoading(false)
      if (historyPageId) {
        navigate(`/pages/${historyPageId}`)
      } else {
        navigate(`/`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleCancel = () => {
    if (historyPageId) {
      navigate(`/pages/${historyPageId}`)
    } else {
      navigate(`/`)
    }
  }

  return (
    <Box>
      <Helmet>
        <title>{`Treegress - ${project?.name} Project Settings`}</title>
      </Helmet>
      <Header title={project ? project?.name : "Project"} />
      <Box sx={{ display: 'flex', pt: '56px' }}>
        <Sidebar />
        {project && (
          <Box className="projectSettings">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography variant="h6">Project Settings</Typography>
              <Box>
                <Button onClick={handleCancel} variant="contained" color="secondary" sx={{ width: '80px', textTransform: 'none', mr: 2 }}>
                  Cancel
                </Button>
                {saveProjectLoading ? (
                  <LoadingButton
                    loading
                    variant="contained"
                    color="secondary"
                    loadingIndicator={<CircularProgress color="white" size={16} />}
                    sx={{ width: '80px', textTransform: 'none', mr: 2 }}
                  >
                    Save
                  </LoadingButton>
                ) : (
                  <Button onClick={handleSave} variant="contained" color="secondary" sx={{ width: '80px', textTransform: 'none', mr: 2 }}>
                    Save
                  </Button>
                )}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '72px' }}>
              <DriveFileRenameOutlineOutlinedIcon sx={{ mr: '8px' }} />
              <Typography sx={{ width: '160px', m: 0, fontSize: '1.25rem' }}>Project Name:</Typography>
              <TextField
                size='small'
                value={projectName}
                onChange={handleProjectNameChange}
                required
                error={!!projectNameError}
                helperText={projectNameError + ' '}
                sx={{ width: '300px', height: '40px', m: 0, fontSize: '1.25rem' }}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '72px' }}>
              <LinkOutlinedIcon sx={{ mr: '8px' }} />
              <Typography sx={{ width: '160px', m: 0, fontSize: '1.25rem' }}>Project URL:</Typography>
              <Typography sx={{ m: 0, fontSize: '1.25rem' }}>{project?.url}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '72px' }}>
              <BuildCircleOutlinedIcon sx={{ mr: '8px' }} />
              <Typography sx={{ width: '160px', m: 0, fontSize: '1.25rem' }}>Environment:</Typography>
              <FormControl sx={{ minWidth: 120 }} size="small">
                <CustomSelectWithInput
                  value={environment}
                  handleEnvironmentChange={handleEnvironmentChange}
                  defaultOptions={["Staging", "Production"]}
                />
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '72px' }}>
              <OpenInBrowserOutlinedIcon sx={{ mr: '8px' }} />
              <Typography sx={{ width: '160px', m: 0, fontSize: '1.25rem' }}>Browsers:</Typography>
              <Typography sx={{ width: '200px', m: 0, fontSize: '1.25rem' }}>Chrome</Typography>
            </Box>
            <Alert className="projectSettingsAlert" variant="outlined" severity="info" sx={{ width: '626px', p: '8px' }}>
              <AlertTitle><strong>Important</strong></AlertTitle>
              Updates to the settings below will be applied during the next project page scan.
            </Alert>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '72px' }}>
              <AspectRatioOutlinedIcon sx={{ mr: '8px' }} />
              <Typography sx={{ width: '160px', m: 0, fontSize: '1.25rem' }}>Resolutions:</Typography>
              <FormControl component="fieldset" variant="standard">
                <FormGroup sx={{ flexDirection: 'row' }}>
                  <FormControlLabel
                    control={
                      <Checkbox disabled checked={resolutions.desktop} onChange={handleResolutionsChange} name="desktop" />
                    }
                    label="Desktop"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox disabled checked={resolutions.tablet} onChange={handleResolutionsChange} name="tablet" />
                    }
                    label="Tablet"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox disabled checked={resolutions.mobile} onChange={handleResolutionsChange} name="mobile" />
                    }
                    label="Mobile"
                  />
                </FormGroup>
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '72px' }}>
              <ScienceOutlinedIcon sx={{ mr: 1 }} />
              <Typography sx={{ width: '160px', minWidth: '160px', m: 0, fontSize: '1.25rem' }}>UI Tests:</Typography>
              <FormControl error={projectIssueTypesError} sx={{ maxHeight: '40px' }}>
                <Select
                  size='small'
                  sx={{ width: '300px', height: '40px', textTransform: 'capitalize' }}
                  multiple
                  value={projectIssueTypes}
                  onChange={handleProjectIssueTypesChange}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={{
                    PaperProps: {
                      onMouseDown: (event) => event.stopPropagation(),
                    },
                    autoFocus: false,
                  }}
                >
                  {allIssueTypes.map((type) => (
                    <MenuItem key={type.id} value={type.description}>
                      <Checkbox checked={projectIssueTypes.indexOf(type.description) > -1} disabled={type.description === "Server response error (e.g., 401, 403)"} />
                      <ListItemText primary={<span style={{ textTransform: 'capitalize' }}>{type.description}</span>} />
                    </MenuItem>
                  ))}
                </Select>
                {projectIssueTypesError && <FormHelperText>Please select at least one test type</FormHelperText>}
              </FormControl>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ProjectSettings
