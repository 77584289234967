import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Container, Box } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import ApiService from '../services/ApiService'

const EmailVerification = () => {
  const { token } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const data = await ApiService.get(`/auth/verify-email/${token}`)
        if (data.message === 'Email verified successfully') {
          navigate('/')
        } else {
          console.error('Verification failed:', data.error)
        }
      } catch (error) {
        console.error('Error verifying email:', error)
      }
    }

    verifyEmail()
  }, [navigate, token])

  return (
    <Container
      maxWidth="xs"
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <Helmet>
        <title>Treegress - Email verification</title>
      </Helmet>
      <Box
        sx={{
          border: '1px solid #C4C4C4',
          borderRadius: '8px',
          padding: '32px'
        }}
      >
        Verifying email...
      </Box>

    </Container>
  )
}

export default EmailVerification