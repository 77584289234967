import { Box, Pagination, Typography } from '@mui/material'

const PaginationComponent = ({ page, count, totalItems, itemsPerPage, handlePageChange }) => {
  const startItem = (page - 1) * itemsPerPage + 1
  const endItem = Math.min(page * itemsPerPage, totalItems)

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 2 }}>
      <Typography variant="body2" mr={1}>
        {`${startItem}-${endItem} of ${totalItems}`}
      </Typography>
      <Pagination
        count={count}
        page={page}
        onChange={handlePageChange}
        shape="rounded"
        color="primary"
      />
    </Box>
  )
}

export default PaginationComponent