import { useEffect, useState } from 'react'
import Intercom from '@intercom/messenger-js-sdk'
import { useAuth } from '../context/AuthContext'
import ApiService from '../services/ApiService'

export default function IntercomMessenger() {
  const { user } = useAuth()
  const [intercomId, setIntercomId] = useState(null)

  useEffect(() => {
    const fetchIntercomId = async () => {
      try {
        const res = await ApiService.get('/settings')
        if (res.environment === "production") {
          setIntercomId(res.intercomId)
        }
      } catch (error) {
        console.error("Failed to fetch Intercom ID:", error)
      }
    }

    fetchIntercomId()
  }, [])

  useEffect(() => {
    if (intercomId && user) {
      Intercom({
        app_id: intercomId,
        user_id: user.id,
        name: user.full_name,
        email: user.email,
        created_at: Math.floor(new Date(user.created_at).getTime() / 1000),
      })
    }
  }, [intercomId, user])

  return null
}