import { useNavigate } from 'react-router-dom'
import { TableCell, TableRow, Box, Checkbox, IconButton, Tooltip } from '@mui/material'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { useProject } from '../../context/ProjectContext'
import { formatDate } from '../../utils/formatDate'

const ProjectListItem = ({ project, checkedProjects, handleCheckboxChange, handleOpenDeleteDialog }) => {
  const navigate = useNavigate()
  const { selectedProject, setSelectedProject } = useProject()

  const statusStyles = {
    'Not Started': { backgroundColor: '#6D6E6F', color: '#fff' },
    'In Progress': { backgroundColor: '#4574D2', color: '#fff' },
    'Scanned': { backgroundColor: '#198754', color: '#fff' }
  }

  const handleOpenProject = () => {
    setSelectedProject(project)
    navigate('/pages')
  }

  const handleOpenProjectSettings = () => {
    navigate(`/project/${project?.id}`)
  }

  return (
    <TableRow
      key={project?.id}
      hover
      onClick={handleOpenProject}
      sx={{
        backgroundColor: project?.id === selectedProject?.id ? 'rgba(69, 116, 210, 0.1)' : 'inherit',
        cursor: 'pointer'
      }}
    >
      <TableCell padding="checkbox" onClick={(e) => e.stopPropagation()}>
        <Checkbox
          checked={checkedProjects.includes(project?.id)}
          onChange={() => handleCheckboxChange(project?.id)}
        />
      </TableCell>
      <TableCell>{project.name}</TableCell>
      <TableCell sx={{ textAlign: 'center' }}>
        <Box sx={{ ml: 2 }}>
          {formatDate(project.created_at)}
        </Box>
      </TableCell>
      <TableCell sx={{ textAlign: 'center' }}>
        <Box sx={{ ml: 2 }}>
          {project?.last_scanned_at ? formatDate(project.last_scanned_at) : 'Unscanned'}
        </Box>
      </TableCell>
      <TableCell>
        <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
          <Box
            variant="contained"
            size="small"
            sx={{
              ...statusStyles[project.status],
              display: 'flex',
              justifyContent: 'center',
              width: '150px',
              minWidth: '120px',
              fontSize: '14px',
              borderRadius: '16px',
              p: '4px 16px',
              ml: 2,
              textTransform: 'capitalize'
            }}
          >
            {project.status}
          </Box>
        </Box>
      </TableCell>
      <TableCell>{project.url}</TableCell>
      <TableCell sx={{ minWidth: '120px', textAlign: 'center' }}>
        <Tooltip
          title="Project settings"
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                backgroundColor: 'primary.main'
              },
            },
          }}
        >
          <IconButton sx={{ mr: 1 }} onClick={(e) => {e.stopPropagation(); handleOpenProjectSettings()}}>
            <SettingsOutlinedIcon color='primary' />
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Delete project"
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                backgroundColor: 'primary.main'
              },
            },
          }}
        >
          <IconButton onClick={(e) => {e.stopPropagation(); handleOpenDeleteDialog(project?.id)}}>
            <DeleteOutlineOutlinedIcon color='primary' />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default ProjectListItem