import { Box, Button, Typography, Accordion, AccordionSummary, AccordionDetails, Card, CardContent, Tooltip } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import IssueLayer from './IssueLayer'
import { sortIssues } from '../../../../utils/sortIssues'

const PageBlocksAccordion = ({ pageBlocks, selectedSection, setSelectedSection, selectedIssue, setSelectedIssue, handleBlockModalOpen }) => {
  const handleSectionClick = (block) => {
    setSelectedSection(block)
    if (block.Issues.length) {
      setSelectedIssue(sortIssues(block.Issues)[0])
    } else {
      setSelectedIssue(null)
    }
  }

  const handleIssueClick = (issue) => setSelectedIssue(issue)

  return (
    <Box className="pageBlocksAccordionContainer">
      <Accordion defaultExpanded sx={{ borderRadius: '8px !important', boxShadow: 'none' }}>
        <AccordionSummary className="accordionSummary" expandIcon={<ExpandMoreIcon />}>
          <Typography>Page analysis results</Typography>
        </AccordionSummary>
        <AccordionDetails className="accordionDetails">
          <Box sx={{ width: '20%', height: '50vh' }}>
            <Card className="accordionDetailsSectionsContainer">
              <CardContent sx={{ minWidth: '100%', p: '4px 8px 8px 8px !important' }}>
                <Typography variant="h6" sx={{ height: '32px' }}>Sections</Typography>
                <Box className="accordionDetailsSectionsContent">
                  {pageBlocks.map((block, index) => (
                    <Button
                      key={block.id}
                      className="accordionDetailsSectionsContentButton"
                      onClick={() => handleSectionClick(block)}
                      sx={{ backgroundColor: selectedSection?.id === block.id ? 'primary.medium' : 'transparent' }}
                    >
                      {block.name ? block.name : `Scroll ${index+1}`}
                      {block.Issues.length ? <span className="accordionDetailsSectionsContentButtonIssues">{block.Issues.length}</span> : null}
                    </Button>
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Box>
          <Box sx={{ width: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
            <Card onClick={handleBlockModalOpen} sx={{ cursor: 'pointer', boxShadow: 'none', borderRadius: '0 0 8px 8px' }}>
              <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: '8px !important' }}>
                <Box sx={{ position: 'relative', lineHeight: 0 }}>
                  <img
                    className="accordionDetailsImage"
                    src={selectedSection?.img_storage_link}
                    alt="Block Screenshot"
                  />
                  {selectedIssue &&
                    <IssueLayer
                      top={selectedIssue.b_box_relative_top}
                      right={selectedIssue.b_box_relative_right}
                      bottom={selectedIssue.b_box_relative_bottom}
                      left={selectedIssue.b_box_relative_left}
                    />
                  }
                </Box>
              </CardContent>
            </Card>
          </Box>
          <Box sx={{ width: '20%', height: '50vh' }}>
            <Card className="accordionDetailsIssuesContainer">
              <CardContent sx={{ minWidth: '100%', p: '4px 8px 8px 8px !important' }}>
                <Typography variant="h6" sx={{ height: '32px' }}>Issues</Typography>
                <Box className="accordionDetailsIssuesContent">
                  { selectedSection.Issues.length ?
                    <>
                      { selectedSection.Issues.map(
                        (issue) => (
                          <Button
                            key={issue.id}
                            className="accordionDetailsIssuesContentButton"
                            onClick={() => handleIssueClick(issue)}
                            sx={{ backgroundColor: selectedIssue === issue ? 'primary.medium' : 'transparent' }}
                          >
                            <span style={{ textAlign: 'left' }}>{issue.type}</span>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <span className="accordionDetailsIssuesContentButtonConfidence">{Math.round(issue.confidence * 100)}%</span>
                              <Tooltip
                                title="Confidence"
                                PopperProps={{
                                  sx: {
                                    '& .MuiTooltip-tooltip': {
                                      backgroundColor: 'primary.main'
                                    },
                                  },
                                }}
                              >
                                <InfoOutlinedIcon fontSize="small" color='primary' sx={{ ml: 0.5 }}/>
                              </Tooltip>
                            </Box>
                          </Button>
                        )
                      )
                      }
                    </>
                    : <Typography className="accordionDetailsIssuesContentText">No issues</Typography>
                  }
                </Box>
              </CardContent>
            </Card>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default PageBlocksAccordion