import { useEffect } from 'react'
import { connectSocket } from '../socket'
import { useAuth } from '../context/AuthContext'

export const useSocket = () => {
  const { userId } = useAuth()
  // eslint-disable-next-line no-undef
  const serverUrl = process.env.REACT_APP_SERVER_URL

  useEffect(() => {
    if (userId) {
      const socketEventEmitter = connectSocket(serverUrl, userId)

      return () => {
        socketEventEmitter.removeAllListeners()
      }
    }
  }, [userId, serverUrl])
}
