import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, Pagination, Checkbox, Menu, MenuItem, IconButton, Tooltip } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useProject } from '../context/ProjectContext'
import ProjectService from '../services/ProjectService'
import Header from "../components/Header"
import Sidebar from "../components/Sidebar"
import ProjectListItem from '../components/projects/ProjectListItem'
import SortingIcon from '../components/SortingIcon'
import CreateProjectModal from '../components/modals/CreateProjectModal'
import DeleteProjectsConfirmationModal from '../components/modals/DeleteProjectsConfirmationModal'
import { sortTableItems } from '../utils/sortTableItems'

const Projects = () => {
  const { selectedProject } = useProject()
  const [projectsList, setProjectsList] = useState([])
  const [checkedProjects, setCheckedProjects] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [projectsTotal, setProjectsTotal] = useState(0)
  const [open, setOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [projectName, setProjectName] = useState('')
  const [projectURL, setProjectURL] = useState('')
  const [projectsToggler, setProjectsToggler] = useState(false)
  const [projectsToDelete, setProjectsToDelete] = useState([])
  const [isHeaderCheckboxChecked, setIsHeaderCheckboxChecked] = useState(false)
  const [sortConfig, setSortConfig] = useState({ key: 'project', direction: 'asc' })
  const [page, setPage] = useState(1)
  const openAnchorEl = Boolean(anchorEl)
  const projectsPerPage = 10
  const startItem = (page - 1) * projectsPerPage + 1
  const endItem = Math.min(page * projectsPerPage, projectsTotal)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await ProjectService.getAllProjects(1, 1000)
        if (!res.projects.length && page === 1) {
          setOpen(true)
        }
        setProjectsList(res.projects)
        setProjectsTotal(res.count)
      } catch (error) {
        console.error('Error fetching projects:', error)
      }
    }

    fetchData()
  // eslint-disable-next-line
  }, [projectsToggler])

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  const handleOpenDeleteDialog = (projectID) => {
    if (projectID) {
      setProjectsToDelete([projectID])
    } else {
      setProjectsToDelete(checkedProjects)
    }
    setDeleteOpen(true)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClickCheckAllDropdown = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseCheckAllDropdown = () => {
    setAnchorEl(null)
  }

  const handleCheckAllCurrentPage = () => {
    const currentPageProjectIds = paginatedProjects.map(project => project.id)
    setCheckedProjects(prevCheckedProjects => [
      ...new Set([...prevCheckedProjects, ...currentPageProjectIds])
    ])
    setIsHeaderCheckboxChecked(true)
    handleCloseCheckAllDropdown()
  }

  const handleUncheckAllCurrentPage = () => {
    const currentPageProjectIds = paginatedProjects.map(project => project.id)
    setCheckedProjects(prevCheckedProjects =>
      prevCheckedProjects.filter(projectId => !currentPageProjectIds.includes(projectId))
    )
    setIsHeaderCheckboxChecked(false)
    handleCloseCheckAllDropdown()
  }

  const handleCheckboxChange = (id) => {
    setCheckedProjects(prevCheckedProjects => {
      if (prevCheckedProjects.includes(id)) {
        return prevCheckedProjects.filter(projectId => projectId !== id)
      } else {
        return [...prevCheckedProjects, id]
      }
    })
  }

  const handleSortChange = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }))
  }

  const sortedProjects = sortTableItems(projectsList, sortConfig)

  const paginatedProjects = sortedProjects.slice((page - 1) * projectsPerPage, page * projectsPerPage)

  return (
    <Box>
      <Helmet>
        {selectedProject?.name ?
          <title>{`Treegress - ${selectedProject.name} Projects`}</title> :
          <title>Treegress - Projects</title>
        }
      </Helmet>
      <Header title="Projects" projectsToggler={projectsToggler}/>
      <Box sx={{ display: 'flex', pt: '56px' }}>
        <Sidebar projectsListLength={projectsList.length}/>
        <Box className='projects'>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">Projects</Typography>
            <Box>
              {checkedProjects.length ?
                <Button variant="outlined" color="error" onClick={() => handleOpenDeleteDialog()} sx={{  width: '200px', mr: 2, textTransform: 'none' }}>
                  Delete Projects
                </Button> : null
              }
              <Button variant="contained" color="secondary" onClick={handleClickOpen} sx={{ width: '200px', textTransform: 'none' }}>
                Add New Project
              </Button>
            </Box>
          </Box>
          <TableContainer className='projectsTableContainer' component={Paper} sx={{ boxShadow: 'none' }}>
            <Table className='projectsTable' stickyHeader>
              <TableHead sx={{ borderBottom: '2px solid #D7E2EC' }}>
                <TableRow>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        sx={{ ml: '17px', p: 0 }}
                        checked={isHeaderCheckboxChecked}
                        onChange={(e) => {
                          const allChecked = e.target.checked
                          if (allChecked) {
                            handleCheckAllCurrentPage()
                          } else {
                            handleUncheckAllCurrentPage()
                          }
                        }}
                      />
                      <IconButton
                        onClick={handleClickCheckAllDropdown}
                        size="small"
                        sx={{ p: 0 }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={openAnchorEl}
                        onClose={handleCloseCheckAllDropdown}
                      >
                        <MenuItem onClick={handleCheckAllCurrentPage} sx={{ minWidth: '100px' }}>All</MenuItem>
                        <MenuItem onClick={handleUncheckAllCurrentPage}>None</MenuItem>
                      </Menu>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ minWidth: '200px', textAlign: 'center', cursor: 'pointer' }} onClick={() => handleSortChange('name')}>
                    <Box className="tableCellContentWithBorder">
                      <SortingIcon sortConfig={sortConfig} currentKey={'name'}/>
                      Project
                    </Box>
                  </TableCell>
                  <TableCell sx={{ minWidth: '202px', textAlign: 'center', cursor: 'pointer' }} onClick={() => handleSortChange('created_at')}>
                    <Box className="tableCellContentWithBorder">
                      <SortingIcon sortConfig={sortConfig} currentKey={'created_at'}/>
                      Last Discovery Date
                    </Box>
                  </TableCell>
                  <TableCell sx={{ minWidth: '160px', textAlign: 'center', cursor: 'pointer' }} onClick={() => handleSortChange('last_scanned_at')}>
                    <Box className="tableCellContentWithBorder">
                      <SortingIcon sortConfig={sortConfig} currentKey={'last_scanned_at'}/>
                      Last Scan Date
                    </Box>
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleSortChange('status')}>
                    <Box className="tableCellContentWithBorder">
                      <SortingIcon sortConfig={sortConfig} currentKey={'status'}/>
                      Scan Status
                      <Tooltip
                        title={
                          <ul className="scanStatusTooltip">
                            <li id='notStarted'>
                              <strong>Not Started</strong>: You haven&apos;t scanned any pages yet.
                            </li>
                            <li>
                              <strong>In Progress</strong>: Not all of your discovered pages have been scanned.
                            </li>
                            <li>
                              <strong>Scanned</strong>: All discovered pages have been scanned.
                            </li>
                          </ul>
                        }
                        PopperProps={{
                          sx: {
                            maxWidth: '420px',
                            '& .MuiTooltip-tooltip': {
                              maxWidth: '420px',
                              backgroundColor: 'primary.main'
                            },
                          },
                        }}
                      >
                        <IconButton size="small" sx={{ p: 0, ml: 0.5 }}>
                          <InfoOutlinedIcon fontSize="small" color='primary'/>
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleSortChange('url')}>
                    <Box className="tableCellContentWithBorder">
                      <SortingIcon sortConfig={sortConfig} currentKey={'url'}/>
                      URL
                    </Box>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { paginatedProjects.length ?
                  paginatedProjects.map((project) => (
                    <ProjectListItem key={project.id} project={project} checkedProjects={checkedProjects} handleCheckboxChange={handleCheckboxChange} handleOpenDeleteDialog={handleOpenDeleteDialog}/>
                  )) :
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Typography variant="body1" align="center">
                        No data available. Start by adding a new project using the &apos;Add New Project&apos; button.
                      </Typography>
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
          { projectsList.length ?
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 2 }}>
              <Typography variant="body2" mr={1}>
                {`${startItem}-${endItem} of ${projectsTotal}`}
              </Typography>
              <Pagination
                count={Math.ceil(projectsTotal / projectsPerPage)}
                page={page}
                onChange={handlePageChange}
                shape="rounded"
                color='primary'
              />
            </Box> : null }
        </Box>
      </Box>
      <CreateProjectModal
        open={open}
        setOpen={setOpen}
        projectName={projectName}
        setProjectName={setProjectName}
        projectURL={projectURL}
        setProjectURL={setProjectURL}
        projectsToggler={projectsToggler}
        setProjectsToggler={setProjectsToggler}
        isExistedProjects={Boolean(projectsList.length)}
      />
      <DeleteProjectsConfirmationModal
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        projectsToggler={projectsToggler}
        setProjectsToggler={setProjectsToggler}
        projectsToDelete={projectsToDelete}
        setCheckedProjects={setCheckedProjects}
      />
    </Box>
  )
}

export default Projects