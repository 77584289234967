import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { HelmetProvider } from 'react-helmet-async'
import theme from './theme'
import './styles/index.scss'
import Hotjar from './utils/Hotjar'
import IntercomMessenger from './utils/IntercomMessenger'
import Projects from './pages/Projects'
import Dashboard from './pages/Dashboard'
import Pages from './pages/Pages'
import DiscoveryPage from './pages/DiscoveryPage'
import Profile from './pages/Profile'
import Tutorial from './pages/Tutorial'
import TaskManagement from './pages/TaskManagement'
import Login from './pages/Login'
import SignUp from './pages/SignUp'
import EmailConfirmation from './pages/EmailConfirmation'
import EmailVerification from './pages/EmailVerification'
import SharedReport from './pages/SharedReport'
import { AuthProvider } from './context/AuthContext'
import { AppWithNotifications } from './context/NotificationContext'
import { AllProjectsProvider } from './context/AllProjectsContext'
import { ProjectProvider } from './context/ProjectContext'
import { SidebarProvider } from './context/SidebarContext'
import ProtectedRoute from './components/ProtectedRoute'
import ProjectSettings from './pages/ProjectSettings'
import addExternalScripts from './utils/addExternalScripts'

addExternalScripts()

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Projects />
      </ProtectedRoute>
    ),
    errorElement: <div>404 Not Found</div>
  },
  {
    path: "dashboard",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: "pages",
    element: (
      <ProtectedRoute>
        <Pages />
      </ProtectedRoute>
    ),
  },
  {
    path: "pages/:id",
    element: (
      <ProtectedRoute>
        <DiscoveryPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "project/:id",
    element: (
      <ProtectedRoute>
        <ProjectSettings />
      </ProtectedRoute>
    ),
  },
  {
    path: "profile",
    element: (
      <ProtectedRoute>
        <Profile />
      </ProtectedRoute>
    ),
  },
  {
    path: "tutorial",
    element: (
      <ProtectedRoute>
        <Tutorial />
      </ProtectedRoute>
    ),
  },
  {
    path: "tasks",
    element: (
      <ProtectedRoute>
        <TaskManagement />
      </ProtectedRoute>
    ),
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "signup",
    element: <SignUp />,
  },
  {
    path: "confirmation",
    element: <EmailConfirmation />,
  },
  {
    path: "verify-email/:token",
    element: <EmailVerification />,
  },
  {
    path: "report/:id",
    element: <SharedReport />,
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <AuthProvider>
        <AppWithNotifications>
          <AllProjectsProvider>
            <ProjectProvider>
              <SidebarProvider>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <Hotjar />
                  <IntercomMessenger />
                  <RouterProvider router={router} />
                </ThemeProvider>
              </SidebarProvider>
            </ProjectProvider>
          </AllProjectsProvider>
        </AppWithNotifications>
      </AuthProvider>
    </HelmetProvider>
  </React.StrictMode>
)