import { Box, Button } from '@mui/material'
import DropdownButton from './DropdownButton'

const ActionButtons = ({ checkedPages, handleDiscoverMore, handleUpadateIncludingStatus, handleScanPages, handleAddPageOpen }) => (
  <Box>
    { checkedPages.length ?
      <Button
        id='discoverMoreButton'
        className='checkedPagesButton'
        variant="outlined"
        color="secondary"
        onClick={handleDiscoverMore}
        sx={{ mx: 1 }}
      >
          Discover more
      </Button> : null
    }
    { checkedPages.length ?
      <>
        <Button
          id='excludePagesButton'
          className='checkedPagesButton'
          variant="outlined"
          color="secondary"
          onClick={() => handleUpadateIncludingStatus(false)}
        >
            Exclude Pages
        </Button>
        <Button
          id='includePagesButton'
          className='checkedPagesButton'
          variant="outlined"
          color="secondary"
          onClick={() => handleUpadateIncludingStatus(true)}
        >
            Include Pages
        </Button>
      </> : null
    }

    <Button
      id='addPageButton'
      variant="contained"
      color="secondary"
      onClick={handleAddPageOpen}
      sx={{ width: '108px', textTransform: 'none', mr: 1 }}
    >
      Add Page
    </Button>
    <DropdownButton title={checkedPages.length ? "Scan Selected Pages" : "Scan All Pages" } handleScanPages={handleScanPages}/>
  </Box>
)

export default ActionButtons