import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Box, Typography, TextField, IconButton } from '@mui/material'
import { Edit, Save } from '@mui/icons-material'
import MailOutlinedIcon from '@mui/icons-material/MailOutlined'
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined'
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import ApiService from '../services/ApiService'
import { validateName, validatePassword } from '../utils/validateForm'

const Profile = () => {
  const [user, setUser] = useState(null)
  const [isEditingName, setIsEditingName] = useState(false)
  const [isEditingPassword, setIsEditingPassword] = useState(false)
  const [newName, setNewName] = useState(null)
  const [nameError, setNameError] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const [currentPasswordError, setCurrentPasswordError] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordError, setNewPasswordError] = useState('')

  useEffect(() => {
    const getUserData = async () => {
      const userData = await ApiService.get('/auth/profile')
      setUser(userData)
      setNewName(userData.full_name)
    }

    getUserData()
  },[])

  const handleEditName = () => {
    setIsEditingName(true)
  }

  const handleNameChange = (e) => {
    setNewName(e.target.value)
    if (nameError) {
      setNameError('')
    }
  }

  const handleSaveName = async () => {
    if (!validateName(newName)) {
      setNameError('Required field.')
      return
    } else {
      setNameError('')
    }

    setUser((prevUser) => ({ ...prevUser, full_name: newName }))
    await ApiService.put('/auth/profile', { email: user.email, full_name: newName })
    setIsEditingName(false)
  }

  const handleEditPassword = () => {
    setIsEditingPassword(true)
  }

  const handleCurrentPasswordChange = (e) => {
    setCurrentPassword(e.target.value)
    if (currentPasswordError) {
      setCurrentPasswordError('')
    }
  }

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value)
    if (newPasswordError) {
      setNewPasswordError('')
    }
  }

  const handleSavePassword = async () => {
    let valid = true

    if (!validatePassword(currentPassword)) {
      setCurrentPasswordError('Invalid password. Minimum length 6.')
      valid = false
    } else {
      setCurrentPasswordError('')
    }

    if (!validatePassword(newPassword)) {
      valid = false
      setNewPasswordError('Invalid password. Minimum length 6.')
      return
    } else {
      setNewPasswordError('')
    }

    if (newPassword === currentPassword) {
      valid = false
      setNewPasswordError('New password must be different from current')
      return
    } else {
      setNewPasswordError('')
    }

    if (!valid) return

    if (currentPassword.length >= 6 && newPassword.length >= 6 && currentPassword !== newPassword) {
      try {
        await ApiService.put('/auth/change-password', { currentPassword, newPassword })
        setIsEditingPassword(false)
        setCurrentPassword('')
        setNewPassword('')
      } catch (error) {
        console.log(error)
        if (error.response.data.error === "Current password is incorrect") {
          setCurrentPasswordError("Current password is incorrect")
        } else {
          console.log(error.response.data.error)
        }
      }
    }
  }

  return (
    <Box className="profile">
      <Helmet>
        <title>Treegress - Profile</title>
      </Helmet>
      <Header title="Profile"/>
      <Box sx={{ display: 'flex', pt: '56px' }}>
        <Sidebar />
        { user && <Box className="profileContent">
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" gutterBottom>Profile Information</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '72px' }}>
            <MailOutlinedIcon sx={{ mr: '8px' }}/>
            <Typography sx={{ width: '160px', m: 0, fontSize: '1.25rem' }}>Email:</Typography>
            <Typography sx={{ width: '300px', m: 0, fontSize: '1.25rem' }}>{user.email}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '72px' }}>
            <BadgeOutlinedIcon sx={{ mr: '8px' }}/>
            <Typography sx={{ width: '160px', m: 0, fontSize: '1.25rem' }}>Full Name:</Typography>
            {isEditingName ? (
              <TextField
                size='small'
                sx={{ width: '300px', height: '40px', m: 0, fontSize: '1.25rem' }}
                value={newName}
                onChange={handleNameChange}
                required
                error={!!nameError}
                helperText={nameError+' '}
              />
            ) : (
              <Typography size='small' sx={{ width: '300px', m: 0, fontSize: '1.25rem' }}>{user.full_name}</Typography>
            )}
            <IconButton onClick={isEditingName ? handleSaveName : handleEditName} sx={{ ml: 0.5 }}>
              {isEditingName ? <Save color='primary'/> : <Edit color='primary'/>}
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '72px'  }}>
            <PasswordOutlinedIcon sx={{ mr: '8px' }}/>
            <Typography sx={{ width: '160px', m: 0, fontSize: '1.25rem' }}>Password:</Typography>
            {isEditingPassword ? (
              <Box sx={{ display: 'flex', alignItems: 'center', height: '72px'  }}>
                <TextField
                  label="Current Password"
                  type="password"
                  size='small'
                  value={currentPassword}
                  onChange={handleCurrentPasswordChange}
                  sx={{ width: '300px', height: '40px', m: 0, fontSize: '1.25rem', mr: 2 }}
                  required
                  error={!!currentPasswordError}
                  helperText={currentPasswordError+' '}
                />
                <TextField
                  label="New Password"
                  type="password"
                  size='small'
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                  sx={{ width: '300px', height: '40px', m: 0, fontSize: '1.25rem' }}
                  required
                  error={!!newPasswordError}
                  helperText={newPasswordError+' '}
                />
                <IconButton onClick={handleSavePassword} sx={{ ml: 0.5 }}>
                  <Save color='primary'/>
                </IconButton>
              </Box>
            ) : (
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ width: '300px', height: '40px' }}/>
                {user.hasPassword ?
                  <IconButton onClick={handleEditPassword} sx={{ ml: 0.5 }}>
                    <Edit color='primary'/>
                  </IconButton> :
                  <IconButton disabled sx={{ ml: 0.5 }}>
                    <Edit />
                  </IconButton>
                }
              </Box>
            )}
          </Box>
        </Box>}
      </Box>
    </Box>
  )
}

export default Profile