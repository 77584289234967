import { Box, Typography } from '@mui/material'

const TestRecommendationsTab = ({ page }) => {
  console.log(page.page.id)
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 170px)' }}>
      <Typography variant="body1">Stay tuned! Functional test case recommendations will show up here with our next platform update.</Typography>
    </Box>
  )
}

export default TestRecommendationsTab