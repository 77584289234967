import { Box } from '@mui/material'

const IssueLayer = ({ top, right, bottom, left }) => {
  const smallBB = right - left < 0.03 || bottom - top < 0.03
  const horzontalExpansion = 0.03
  const verticalExpansion = 0.03
  const expandedTop = Math.max(0, top - verticalExpansion)
  const expandedLeft = Math.max(0, left - horzontalExpansion)
  const expandedRight = Math.min(1, right + horzontalExpansion)
  const expandedBottom = Math.min(1, bottom + verticalExpansion)

  return (
    <Box
      className="boundingBox"
      sx={{
        position: 'absolute',
        top: smallBB ? `${expandedTop * 100}%` : `${top * 100}%`,
        left: smallBB ? `${expandedLeft * 100}%` : `${left * 100}%`,
        width: smallBB ? `${(expandedRight - expandedLeft) * 100}%` : `${(right - left) * 100}%`,
        height: smallBB ? `${(expandedBottom - expandedTop) * 100}%` : `${(bottom - top) * 100}%`,
        border: '4px solid #D32F2F',
        zIndex: 2,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }}
    />
  )
}

export default IssueLayer