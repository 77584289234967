import { Box, Typography } from "@mui/material"

const ProjectSummary = ({ summaryData, hasScans }) => {
  const browserVersions = ["Chrome 128.0.6613.119-1"].join(', ')

  const screenResolutions = [
    summaryData.screenResolutions?.desktop ? "1920x1080" : null,
    summaryData.screenResolutions?.tablet ? "768x1024" : null,
    summaryData.screenResolutions?.mobile ? "414x700" : null
  ].filter(Boolean).join(', ')

  return (
    <Box className="dashboardDataInfo">
      <Box sx={{ display: "flex", alignItems: 'flex-end' }}>
        <Box>
          <Typography variant="h6" gutterBottom>
            Project Summary
          </Typography>
          <Typography>
            <span className="dashboardDataInfoLabel">Project:</span>
            {summaryData.projectName}
          </Typography>
          <Typography>
            <span className="dashboardDataInfoLabel">Last Scan Date:</span>
            { hasScans ? summaryData.lastScanDate : "Unscanned" }
          </Typography>
          { hasScans ?
            <>
              <Typography>
                <span className="dashboardDataInfoLabel">Browser Versions Tested:</span>
                {browserVersions}
              </Typography>
              <Typography>
                <span className="dashboardDataInfoLabel">Screen Resolutions Tested:</span>
                {screenResolutions}
              </Typography>
            </> : null }
        </Box>
        { hasScans ? <Box sx={{ ml: 2, pl: 2, borderLeft: '2px solid #D7E2EC' }}>
          <Typography>
            <span className="dashboardDataInfoLabel">Total Pages Scanned:</span>
            {summaryData.totalPagesScanned}
          </Typography>
          <Typography>
            <span className="dashboardDataInfoLabel">Pages with Issues:</span>
            {summaryData.pagesWithIssues}
          </Typography>
          <Typography>
            <span className="dashboardDataInfoLabel">Issue Rate:</span>
            {Number(summaryData.issueRate.toFixed(2))} %
          </Typography>
          <Typography sx={{ height: '24px' }}></Typography>
        </Box> : null }
      </Box>
      { hasScans ? <Box mt={1}>
        <Typography>
          <span className="dashboardDataInfoLabel" style={{ minWidth: '130px' }}>AI Conclusion:</span>
          { summaryData.pagesWithIssues ?
            <span>
              There are {summaryData.issuesTotal} issues detected, but the good news is you’re aware of them. Now you can fix them! Knowing the problem is the first step toward improvement.
            </span> :
            <span>
              Great job! There are no UI issues detected. Your product’s quality is approved by AI.
            </span>
          }
        </Typography>
      </Box> : null }
    </Box>
  )
}

export default ProjectSummary
