import { Box, TableCell, TableHead, TableRow } from '@mui/material'

const TopPagesTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell align='center' sx={{ minWidth: '180px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            Page Name
          </Box>
        </TableCell>
        <TableCell align='center' sx={{ minWidth: '180px', maxWidth: '300px' }}>
          <Box className="tableCellContentWithBorder" sx={{ display: 'flex', justifyContent: 'center' }}>
            Page URL
          </Box>
        </TableCell>
        <TableCell align='center' sx={{ width: '270px', minWidth: '270px' }}>
          <Box className="tableCellContentWithBorder" sx={{ display: 'flex', justifyContent: 'center' }}>
            Passed UI Tests
          </Box>
        </TableCell>
        <TableCell align='center' sx={{ width: '300px', minWidth: '300px' }}>
          <Box className="tableCellContentWithBorder" sx={{ display: 'flex', justifyContent: 'center' }}>
            Detected UI Issues
          </Box>
        </TableCell>
        <TableCell align='center' sx={{ minWidth: '180px' }}>
          <Box className="tableCellContentWithBorder" sx={{ display: 'flex', justifyContent: 'center' }}>
            Preview
          </Box>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default TopPagesTableHead
