import { Box, Typography, Tooltip } from '@mui/material'

const StatsSummary = ({ title, value, color, tooltipText }) => (
  <Box className="statsSummaryBox">
    <Typography
      variant="body1"
      color='primary'
      sx={{ lineHeight: '20px', fontWeight: 500, mb: 1 }}
    >
      {title}
    </Typography>
    <Typography variant="body1" color={color} sx={{ height: '24px' }}>
      { tooltipText ?
        <Tooltip
          title={tooltipText}
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                backgroundColor: 'primary.main'
              }
            },
          }}
        >
          <span style={{ color: 'inherit', cursor: 'pointer' }}>{value}</span>
        </Tooltip> : value
      }
    </Typography>
  </Box>
)

export default StatsSummary