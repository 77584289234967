import { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Box, Typography } from "@mui/material"
import ProjectService from "../services/ProjectService"
import Header from "../components/Header"
import Sidebar from "../components/Sidebar"
import ProjectSummary from "../components/dashboard/ProjectSummary"
import ShareButton from "../components/dashboard/ShareButton"
import Charts from "../components/dashboard/Charts"
import TopPages from "../components/dashboard/TopPages"
import { useProject } from "../context/ProjectContext"

const Dashboard = () => {
  const { selectedProject } = useProject()
  const [summaryData, setSummaryData] = useState(null)

  useEffect(() => {
    if (selectedProject?.id) {
      const loadData = async () => {
        const data = await ProjectService.getProjectSummary(selectedProject.id)
        setSummaryData(data)
      }
      loadData()
    }
  }, [selectedProject?.id])

  const hasScans = summaryData?.totalPagesScanned > 0

  return (
    <Box className="dashboard" sx={{ height: "100vh", overflow: "hidden" }}>
      <Helmet>
        <title>{`Treegress - ${selectedProject.name} Dashboard`}</title>
      </Helmet>
      <Header title="Dashboard" />
      <Box sx={{ display: "flex", pt: "56px", height: "100%" }}>
        <Sidebar />
        { summaryData ?
          <Box className="dashboardDataContainer">
            <Box className="dashboardData">
              <Box className="dashboardDataInfoContainer">
                <ProjectSummary summaryData={summaryData} hasScans={hasScans} />
                { hasScans ? <ShareButton summaryData={summaryData}/> : null }
              </Box>
              { !hasScans ?
                <Box className="dashboardDataEmpty">
                  <Typography variant="h6" gutterBottom>
                    No scans yet!
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    Head over to <Link to={'../pages'}><span className="dashboardDataEmptyLink">Pages</span></Link> to start your first scan. Once you’ve completed a scan, all your data for analysis will appear here.
                  </Typography>
                </Box> :
                <Box className="dashboardDataStatsContainer">
                  <Charts summaryData={summaryData}/>
                  <TopPages pages={summaryData?.topPages}/>
                </Box>
              }
            </Box>
          </Box> :
          <Box className="dashboardDataContainer">Loading...</Box>
        }
      </Box>
    </Box>
  )
}

export default Dashboard
