import { Container, Box, Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

const EmailConfirmation = () => {
  const navigate = useNavigate()

  const handleGoToSignIn = () => {
    navigate('/')
  }

  return (
    <Container
      maxWidth="sm"
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <Helmet>
        <title>Treegress - Email confirmation</title>
      </Helmet>
      <Box
        sx={{
          border: '1px solid #C4C4C4',
          borderRadius: '8px',
          padding: '24px'
        }}
      >
        <Typography variant="h5" gutterBottom>
          Please confirm your email address
        </Typography>
        <Typography variant="body1" mb={2}>
          We have sent a confirmation email to your provided email address. Please check your inbox and click on the confirmation link.
        </Typography>
        <Button variant="contained" color="secondary" onClick={handleGoToSignIn}>
          Go to Sign in
        </Button>
      </Box>
    </Container>
  )
}

export default EmailConfirmation